@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

.nav-link, .navbar-brand {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    color: white !important;
    transition: color 0.3s;
}

.nav-link:hover, .navbar-brand:hover {
    opacity: 0.75;
    transition: opacity 0.3s;
}

.nav-link.active > .highlight {
    color: #ca8bff !important;
    transition: color 0.3s;
}

.navbar {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1.2rem;
    transition: color 0.3s;
}

.highlight {
    color: #FF9A3C;
    transition: color 0.3s;
}


@media screen and (max-width: 768px) {
    #email {
        display: none;
    }
}