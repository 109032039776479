.footer-text {
    color: white !important;
}

.footer-links {
    border-bottom: #c3c3c3 1px solid;
    margin-left: 20vw;
    margin-right: 20vw;
}

.footer-image:hover {
    opacity: 0.65;
    transition: opacity 0.3s;
}