
.languages {
    margin-left: 10rem;
    font-size: 25px;
}

.description-container {
    margin-left: 10rem;
}

.projects {
    font-size: 60px;
    font-weight: bold;
}

hr {
    margin: 0;
    padding: 0;
}

.vertical-line {
    width: 1px;
    background-color: white;
}

.projects-button {
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
    letter-spacing: 0.1rem;
}

.projects-button:hover {
    background-color: #2c2c2c;
    /*box-shadow: white 2px 2px 2px;*/
}


.projects-button-view {
    background-color: black;
    padding: 0.5rem 2rem;
    color: #FFF;
    border: #FFF 2px solid;
}

.project-languages {
    opacity: 85%;
}


@media screen and (max-width: 1500px) {
    .project-parent-container {
        margin-left: 10vw !important;
    }

    .projects-container {
        gap: 7vh !important;
    }

    .project-container {
        margin-left: 0 !important;
    }

    .languages, .description-container {
        margin-left: 7vw;
    }

    .project-description {
        margin-left: 7vw !important;
    }

    .project-button-container {
        margin-left: 7vw !important;
    }

    .projects-button {
        margin-left: 7vw !important;
    }

}

@media screen and (max-width: 800px) {
    .projects-container, .projects-button {
        font-size: 2vw;
    }
}

@media screen and (max-width: 700px) {
    .projects-container, .projects-button {
        font-size: 2.4vw;
    }
}

@media screen and (max-width: 600px) {
    .projects-container, .projects-button {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 500px) {
    .projects-container, .projects-button {
        font-size: 0.9rem;
    }
}